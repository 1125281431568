<template>
	<b-card title="Inspiration List">
		<b-row class="mb-3">
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="category">Category:</label>
					<v-select
						id="category"
						v-model="filter.nameCategory"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="thread_category_name"
						placeholder="Pilih Category"
						:options="category"
						@input="inputSubCategory($event.id)"
					/>
				</div>
			</b-col>
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="subCategory">Sub Category:</label>
					<v-select
						id="subCategory"
						v-model="filter.sub_category_name"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="thread_subcategory_name"
						placeholder="Pilih Sub Category"
						:options="subCategory"
						:reduce="subCategory => subCategory.thread_subcategory_name"
					/>
				</div>
			</b-col>
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="keyword">Keyword:</label>
					<input 
						id="keyword"
						type="text" 
						class="form-control"
						placeholder="Search: Title"
						v-model="filter.keyword" 
					>
				</div>
			</b-col>
			
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Start Date:</label>
					<flat-pickr
						v-model="filter.start_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>End Date:</label>
					<flat-pickr
						v-model="filter.end_date"
						class="form-control"
						placeholder="Search: Date"
						:disabled="endDate"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>
			
			<b-col cols="12">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="clearFilter()" 
				>
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export
					@click="getDataExport"
				>
					Export
				</button>
			</b-col>
			<Export
				:is-loading="isLoading"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		<Table 
			:result="result"
			:ask-master="askMaster"
			:is-loading="isLoading"
			:get-data="getData"
			:edit-item="editItem"
			:delete-item="deleteItem"
			:current-page="currentPage"
			@filter="getFilter"
		/>
		<Update
			:is-loading="isLoading"
			:edit-id="editId"
			:category="category"
			:sub-category="subCategory"
			:validations="validations"
			:update-item="updateItem"
			:clean-up-form="cleanUpForm"
			:form-payload="formPayload"
			:input-sub-category="inputSubCategory"
			@payload="getPayload($event)"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/Export.vue'
import Table from '@/components/tanya-master/Table.vue'
import Update from '@/components/tanya-master/Update.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Table,
		Update,
		Export,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: [],
			askMaster: [],
			formPayload: Object,
			editId: null,
			link:'',
			isLoadingExport: false,
			tableExport: ['no', 'generated_by', 'export_time', 'note', 'path'],
			filter: {},
			validations: '',
			resultExport: [],
			category: [],
			subCategory:[],
			sort: '',
			filter: {
				nameCategory: '',
				sub_category_name: '',
				start_date: '',
				end_date: '',
				keyword: '',
				order_type: ''
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.filter.category_name = this.filter.nameCategory ? this.filter.nameCategory.thread_category_name : ''
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	mounted() {
		this.categoryThread()
	},
	created() {
		this.getData()
	},
	methods: {
		getFilter(value) {
			this.filter.order_type = value.order_type
		},
		getPayload(value) {
			if (value) {
				this.formPayload = value
			}
		},
		clearFilter() {
			this.filter = {
				nameCategory: '',
				sub_category_name: '',
				start_date: '',
				end_date: '',
				keyword: '',
				order_type: ''
			}
		},
		cleanUpForm() {
			this.editId = null
			this.formPayload = {}
			this.validations = ''
		},
		inputSubCategory(slug) {
			this.$http.get(`/admin/sub-category-threads?category_id=${slug}`)
			.then(response => {
				this.subCategory = response.data.data
				// this.filter.sub_category_name = ''
			})
		},
		categoryThread() {
			this.$http.get('/admin/category-threads')
			.then(response => {
				this.category = response.data.data
			})
		},
		getDataExport() {
			this.isLoadingExport = true
			this.$http.get('/admin/export/thread/list'
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
		exportNow() {
			this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$http.post('/admin/export/thread/export', exportParam).then(response => {
				if(response) {
					this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					})
					this.$bvModal.hide('form-modal-export')
					this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
			})
		},
		editItem(item) {
			this.cleanUpForm()
			this.editId = item.id
			this.formPayload = {
				thread_title: item.thread_title,
				thread_question: item.thread_question,
				category: item.category,
				sub_category: item.sub_category
			}
			this.$bvModal.show('form-modal-ask-master')
		},
		preparePayload() {
			const form = new FormData()
			this.formPayload.id_thread_category = this.formPayload.category.id
			this.formPayload.id_thread_subcategory = this.formPayload.sub_category.ids
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
					if(key != 'category' && key != 'sub_category'){
						form.append(key, this.formPayload[key])
					}
				}
			}
			return form
		},
		updateItem() {
			const form = this.preparePayload()
			form.append("_method", 'PUT')
			this.isLoading = true
			this.$http.post(`/admin/threads/${this.editId}`, form)
			.then(response => {
				this.$bvModal.hide('form-modal-ask-master')
				this.getData()
				successNotification(this, 'Success', 'Tanya Master successfully updated!')
				this.cleanUpForm()
				this.isLoading = false
			}).catch(error => {
				if (error.response.data.meta.message) {
					this.validations = error.response.data.meta.message
				}
				this.isLoading = false
			})
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get('/admin/threads', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.askMaster = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})	
		},
		deleteItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to delete this Tanya Master?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.delete(`/admin/threads/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Tanya Master successfully deleted',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>